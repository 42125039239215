import './style.scss';

import { Spin } from 'antd';

interface SummaryCardProps {
  className?: string;
  isLoading?: boolean;
  value: number;
  label: string;
}

export const SummaryCard = ({
  className,
  isLoading,
  label,
  value,
}: SummaryCardProps) => {
  const renderValue = isLoading ? <Spin /> : value.toLocaleString();
  return (
    <div className={`${className} summary-card`}>
      <h1 className="value">IDR {renderValue}</h1>
      <p className="label">{label}</p>
    </div>
  );
};
